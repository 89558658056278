import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const defaultPage = props => {
  const location = props.location.pathname
  const { page } = props.data
  const { acf } = page

  const metaTitle = acf._swb_theme_meta_title
  const metaDescription = acf._swb_theme_description
  const metaImage = acf._swb_theme_image.localFile.publicURL

  return (
    <Layout location={location}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        metaImg={metaImage}
        location={props.location.pathname}
      />
      <h1>Default Page</h1>
    </Layout>
  )
}

export const homeQuery = graphql`
  query defaultPage($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`

export default defaultPage
